



























































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import Gate from '@/permissions/Gate';
import {PolicyMap} from '@/permissions/types/PolicyMap';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';

export default defineComponent({
  name: 'UofTQuiz3Question1',
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        structure: null as null | string,
        stereoAtom1: null as null | string,
        stereoAtom2: null as null | string,
      },

      optionsStereo: ['R', 'S'],
      imagePrefix: '/img/assignments/UofT/CHMB41H/2023/Quiz3/Question1/',
    };
  },
  computed: {
    seed(): number {
      return this.$inertia.getSeed();
    },
    version(): number {
      return this.taskState.getValueByName('version')?.content?.value ?? 3;
    },
    choices(): string[] {
      return seededShuffle(['A', 'B', 'C', 'D'], this.seed);
    },
    image(): string {
      return this.imagePrefix + `v${this.version}.png`;
    },
    images(): string[] {
      return this.choices.map((l: string) => this.imagePrefix + `v${this.version}choice${l}.png`);
    },
    newmanImageWidth(): number {
      if (this.version === 1) {
        return 192;
      } else if (this.version === 2) {
        return 147;
      } else {
        return 175;
      }
    },
    cardHeight(): number {
      if (this.version === 1) {
        return 200;
      } else if (this.version === 2) {
        return 220;
      } else {
        return 170;
      }
    },
    cardWidth(): number {
      if (this.version === 1) {
        return 270;
      } else if (this.version === 2) {
        return 250;
      } else {
        return 240;
      }
    },
  },
});
